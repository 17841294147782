import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";
const httpEndpoint = import.meta.env.VITE_APP_ApiRootBase + '/graphql';
export const deviceRoot = import.meta.env.VITE_APP_ApiDeviceBase;
export const authRoot = import.meta.env.VITE_APP_ApiAuthBase;
const httpLink = new HttpLink({
  uri: httpEndpoint,
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

// Create a provider
export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }

  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
