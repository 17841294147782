import { defineStore } from "pinia";
import {ref} from 'vue';
import { useAuthStore } from './authStore';
import { deviceRoot } from "../vue-client";

export const useNoteStore = defineStore('note', () => {
    const notes = ref(new Map());
    async function fetchNote(slotId) {
        if (notes.value.has(slotId)) {
            return notes.value.get(slotId);
        }
        else {
            const authStore = useAuthStore();
            const authString = "Bearer " + authStore.authUser.authToken;
            const fetchUrl = `${deviceRoot}/slots/${slotId}/note`;
            const response = await fetch(fetchUrl, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: authString,
              },
            });
        
            const data = await response.json();
            const noteText = data.text;
            notes.value.set(slotId, noteText);
            return noteText;
        }
    }

    async function updateNote(slotId, text) {
        const authStore = useAuthStore();
        const authString = "Bearer " + authStore.authUser.authToken;
        const fetchUrl = `${deviceRoot}/slots/${slotId}/note`;
        const response = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authString,
          },
          body: JSON.stringify({
            text
            }),
        });
        const data = await response.json();
        const updatedText = data.text;
        notes.value.set(slotId, updatedText);
        return updatedText;
    }

    return {
        notes,
        fetchNote,
        updateNote
    }
});