<template>
  <div class="sign-in">
      <Header/>
    <div class="container">
      <div class="row">
        Sign In
      </div>
      <b-form @submit="onSubmit">
        <p v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
          </ul>
        </p>
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
            placeholder="Enter email"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Your Password:" label-for="input-2" type="password">
          <b-form-input type="password" id="input-2" v-model="form.password" required placeholder="Enter password"></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="secondary">Submit</b-button>
      </b-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container{
  max-width: 500px;
  border-top: 3px solid #3f8bea;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  margin: 30px auto;
  background-color: white;
}
</style>

<script lang="ts">
import { provideApolloClient } from '@vue/apollo-composable';
import {apolloClient} from "../vue-client";
import { useMutation } from '@vue/apollo-composable';
import {SIGN_IN} from "../graphql/SignIn";
import router from '../router'
import Header from "../components/Header.vue";
import {useAuthStore} from "../stores/authStore";
export default {
   components: { Header },
  data() {
    return {
      errors: [],
      form: {
        email: "",
        password: ""
      },
      show: true
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      const authStore = useAuthStore();
      // https://stackoverflow.com/questions/67892134/vue-3-apollo-composable-are-we-obliged-to-call-usemutation-and-usequery-insi
      provideApolloClient(apolloClient); 
      this.errors = [];
        const {mutate: authUser, onDone, onError }  = useMutation(SIGN_IN);
        authUser({
            email: this.form.email,
            password: this.form.password
        });

        onDone((signInResult) => {
          authStore.signin(signInResult.data.authUser);
          router.push({ name: 'summary' });
        })

        onError(error => {
          this.errors.push(error.toString()); // Vue doesn't like non-native types as keys, hence string
          authStore.logout();
        })
      } 
    }
  };
</script>
