<template>
    <div v-if="!deviceOffline && data && loaded">
        <div  class="title">
            {{ data.displayName }}
        </div>
        <div class="chart doughnut-chart">
            <DoughnutChart
            class="chart-inner"
            :chartdata="data"
            :options="doughnutOptions"
            :key = "keyPCStatChart"
            />
        </div>
        <div class="chart-value">
            {{ props.feature === 0 ? data.value.toFixed(0) : data.value.toFixed(1) }} <br>
            {{ data.units }}
        </div>
    </div>
</template>

<script setup lang="ts">
import DoughnutChart from "./DoughnutChart.vue";

const props = defineProps({
    deviceOffline: Boolean,
    data: Object,
    doughnutOptions:Object,
    keyPCStatChart: Number,
    loaded: Boolean,
    feature:{
      default:0,
      type:Number
    }
});

</script>
