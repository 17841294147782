import {createWebHistory, createRouter} from "vue-router";
import Summary from "./views/Summary.vue";
import SignIn from "./views/SignIn.vue";
import Slot from "./views/Slot.vue";
import ManageDevice from "./views/ManageDevice.vue";
import CompensatedSlot from "./views/CompensatedSlot.vue";
import Calibration from "./views/Calibration.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "summary",
      component: Summary
    },
    {
      path: "/slots/:id",
      name: "slot",
      component: Slot
    },
    {
      path: "/slots/:id/compensated",
      name: "compensatedSlot",
      component: CompensatedSlot
    },
    {
      path: "/slots/:id/calibration/features/:featureId",
      name: "calibration",
      props: true,
      component: Calibration
    },
    {
      path: "/sign-in",
      name: "signIn",
      component: SignIn
    },
    {
      path: "/manage-device",
      name: "manageDevice",
      component: ManageDevice
    },
  ]
});

export default router;