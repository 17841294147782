import { useAuthStore } from "../stores/authStore";
import { deviceRoot } from "../vue-client";
/**
 * 
 * @param wakeRequest boolean
 * @param deviceId string
 */
export async function callAwakeFunction(wakeRequest, deviceId) {
    const authStore = useAuthStore();
    
    let authString = "Bearer " + authStore.authUser.authToken;
    const actionParam = wakeRequest ? "on" : "off";
    const result = await fetch(`${deviceRoot}/functions/${deviceId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: authString,
        },
        body: JSON.stringify({
            function: "stayAwakeMins",
            param: actionParam,
        }),
    });
    const response = await result.json();
    return response;
}