import { useAuthStore } from '../stores/authStore';
import jwt_decode from 'jwt-decode';
import { useRoute } from 'vue-router'

export function getUserLogoOrganizationId() {
    const authStore = useAuthStore();
    const authUser = authStore.authUser;
    if (authUser === null) {
        const route = useRoute();
        return route.query.orgId;
    }
    else {
        const token = authUser.authToken;
        const decoded = jwt_decode(token);
        const customLogo = decoded.customLogo;
        if (customLogo) {
            return decoded.account._id;
        }
        else {
            return null;
        }
    }
}

export function getUserLogo() {
    const authStore = useAuthStore();
    const authUser = authStore.authUser;
    if (authUser === null) {
        return null;
    }
    else {
        const token = authUser.authToken;
        const decoded = jwt_decode(token);
        const customLogo = decoded.userLogo;
        return customLogo;
    }
}