import { ITrackerData } from "../models/tracker-data.js";
import { IFeature } from "./feature-interface.js";
import {
    getDefaultOptions,
    getLineChartColor,
} from "../utilities/slotUtilities";

import {getColor} from "../utilities/colorUtilities.js";

export function getReportMinMaxPh(reports: Array<number>) {
    // lower bound == maximum min val plot will display (may be <)
    // upper bound == minium max plot will display (may be  > )
    // i.e. if sensor min == 4, lower == 0 display 0
    // i.e. if sensor max == 12, upper == 20 display 20
    // const reports = timeReports[index];
    let max = Math.max(...reports);
    let min = Math.min(...reports);
    return [min, max];
}

export function getAxesMinMaxStepPh(min: number, max: number, numTicks: number, minZero: number) {
    //minZero indicates overwrite y min axes to 0 (not negative), 1 true 0 false
    var axMax = max + Math.abs(max * 0.02);
    var axMin = min - Math.abs(min * 0.02);
    if (minZero == 1 && axMin < 0) {
        axMin = 0;
    }
    var range = axMax - axMin;
    var step = Math.round(range / numTicks);
    if (step === 0) {
        return [axMin, axMax, range / numTicks];
    }
    else {
        axMax = Math.round(axMin + numTicks * step);
        return [axMin, axMax, step];
    }
}

export class PhFeature implements IFeature {
    private _trackerTraces:  ITrackerData[] = [];
    private _plotData: any = null;
    private _plotOptions: any = null;
    private _donutData: any = null;
    private _donutOptions: any = null;
    initialize(trackerTraces: ITrackerData[], device: any): void {
        this._trackerTraces = trackerTraces;
        this._plotData = {
            labels: this._trackerTraces.find((x) => x.sensorName == "timeString").history,
            datasets: [
                {
                    data: this._trackerTraces.find((x) => x.sensorName == "ph").history,
                    fill: false,
                    label: "PH",
                    backgroundColor: getLineChartColor('ph'),
                    borderColor: getLineChartColor('ph'),
                    borderDash: [2],
                    yAxisID: "y_axis_1"
                }     
            ]
        }

        const plotOptions = getDefaultOptions();
        plotOptions.plugins.title.text = 'PH';
        {
            var minMaxSensor = [];
            this._plotData.datasets[0].data = this._trackerTraces.find((x) => x.sensorName == 'ph').history;
            plotOptions.scales.y_axis_1.scaleLabel.labelString = 'ph';
            minMaxSensor = getReportMinMaxPh(
                this._trackerTraces.find((x) => x.sensorName == 'ph').history
            );

            const sensorAx = getAxesMinMaxStepPh(minMaxSensor[0], minMaxSensor[1], 10, 0);
            plotOptions.scales.y_axis_1.suggestedMin = sensorAx[0];
            plotOptions.scales.y_axis_1.suggestedMax = sensorAx[1];
            plotOptions.scales.y_axis_1.ticks.stepSize = sensorAx[2];
        }

        plotOptions.scales.y_axis_2.display = false;
        this._plotOptions = plotOptions;
        
        const targetValue = this._trackerTraces.find((x) => x.sensorName == 'ph').history[this._trackerTraces.find((x) => x.sensorName == 'ph').history.length - 1];
        const alert = device.deviceAlerts.find((x) => x.devicePropName == 'ph');
        const high = (alert == undefined) ? 14 : alert.high;
        const low = (alert == undefined) ? 0 : alert.low;
        const maxValue = high;
        const donutData = {
            displayName: 'PH',
            sensorName: 'ph',
            units: 'PH',
            value: targetValue,
            labels: ['ph', 'total'],
            datasets: [
                {
                    label: 'ph',
                    backgroundColor: [getColor(targetValue, high, low), "#eaeaea"],
                    data: [
                        targetValue > maxValue ? maxValue : targetValue,
                        maxValue > targetValue ? maxValue - targetValue : 0,
                    ],
                },
            ],
        }
        this._donutData = donutData;

        const donutOption = {
            responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 2.5,
                circumference: 180,
                rotation: -90,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                },
                borderWidth: 2
        }

        this._donutOptions = donutOption;    
    }

    getPlotData() {
        return this._plotData;
    }

    getPlotOptions() {
        return this._plotOptions;
    }

    getDonutData(): any {
        return this._donutData;
    }

    getDonutOptions(): any {
        return this._donutOptions;
    }
}