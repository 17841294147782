<script lang="ts" setup>
import { defineAsyncComponent, computed, defineProps } from 'vue';


const props = defineProps({
  featureId: {
    type: String,
    required: true
  }
});

const dynamicComponent = computed(() => {
  if (props.featureId === '2') {
    return defineAsyncComponent(() => import("../features/do-ps-calibration.vue"));
  } else if (props.featureId === '3') {
    return defineAsyncComponent(() => import("../features/ph-calibration.vue"));
  } else {
    return defineAsyncComponent(() => import("../features/algae-tracker-calibration.vue"));
  }
});
</script>

<template>
    <component :is="dynamicComponent"></component>
</template>