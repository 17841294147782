<template>
    <div style="display: inline-block" title="Awake setting" v-if="isArt">
       <BootstrapIcon :icon="currentSlot.wakeRequest? 'eye': 'eye-slash'" small @click="toggleModalShow()"></BootstrapIcon>
        <b-modal v-model="modalShow" :title="`Awake setting for ${currentSlot.name}`" @cancel="cancel" @ok="save">
           <b-form-checkbox :disabled="!isArt" switch size="lg" v-model="currentSlot.wakeRequest">{{currentSlot.wakeRequest? 'On': 'Off'}}</b-form-checkbox>
       </b-modal>
   </div>
</template>

<script setup lang="ts">
import { ref, computed} from "vue";
import { storeToRefs } from 'pinia';
import { useAuthStore } from "../stores/authStore";
import { useSlotStore } from "../stores/slotStore";
import {callAwakeFunction} from "../utilities/awakeUtilities";
import { toast } from 'vue3-toastify';

const modalShow = ref(false);
let awake_default = false; // Keep track of original value

const props = defineProps({
   slotId: {
       type: String,
       default: ''
   }
});

const isArt = computed(() => {
   const authStore = useAuthStore();
   return authStore.isArt;
})

const currentSlot = computed(()=> {
   const slotStore = useSlotStore();
   const {slots} = storeToRefs(slotStore);
   const currentSlotResult = slots.value.find((candidate) => candidate._id === props.slotId);
   return currentSlotResult;
})

const toggleModalShow = async () => {
   // Disable
   if (isArt.value === false) {
       return;
   }

   modalShow.value = !modalShow.value;
   if (modalShow.value) {
    awake_default = currentSlot.value.wakeRequest;
   }
}

const save = async () => {
   const result = await callAwakeFunction(currentSlot.value.wakeRequest, props.slotId);
   currentSlot.value.wakeRequest  = result.wakeRequest;
   const message = result.message;
   // Display message
   toast(message, {autoClose: 5000}); // ToastOptions
}

const cancel = ()=> {
   currentSlot.value.wakeRequest = awake_default;
}
</script>