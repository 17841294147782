export function computeAlertColor(currentValue: number, high: number, low: number) {
    if (currentValue > high || currentValue < low) {
        return 2;
    }

    const delta = high - low;
    const rangeRatio = 0.2; // 20%
    // const lowWarning = userAlertLow + delta * rangeRatio;
    const highWarning = high - delta * rangeRatio;
    if (currentValue > low && currentValue < highWarning) {
        return 0;
    } else {
        return 1;
    }
}

export function getColor(value: number, high: number, low: number) {
    let color = "#8DC050";
    const green = "#8DC050";
    const orange = "#ffa600";
    const red = "#FF0000";
    const computedColor = computeAlertColor(value, high, low);
    switch (computedColor) {
        case 0:
            color = green;
            break;
        case 1:
            color = orange;
            break;
        case 2:
            color = red;
            break;
        default:
            break;
    }
    return color;
}