<template>
     <div title="Activation setting"  style="display: inline-block" v-if="currentSlot && (isArt || !currentSlot.enabled)">
        <BootstrapIcon :icon="currentSlot.enabled? 'lightbulb': 'lightbulb-off'" small @click="toggleModalShow()"></BootstrapIcon>
         <b-modal v-model="modalShow" :title="`Activation setting for ${currentSlot.name}`" @cancel="cancel" @ok="save">
            <b-form-checkbox :disabled="!isArt" switch size="lg" v-model="currentSlot.enabled">{{currentSlot.enabled? 'Enabled': 'Disabled'}}</b-form-checkbox>
        </b-modal>
    </div>
    <div  title="Compensattion enabled" style="display: inline-block" v-if="isArt">
        <BootstrapIcon :icon="'graph-up'" small></BootstrapIcon>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from 'pinia';
import { useAuthStore } from "../stores/authStore";
import { useSlotStore } from "../stores/slotStore";
const modalShow = ref(false);
let enabled_default = false; // Keep track of original value

const props = defineProps({
    slotId: {
        type: String,
        default: ''
    }
});

const isArt = computed(() => {
    const authStore = useAuthStore();
    return authStore.isArt;
})

const currentSlot = computed(()=> {
    const slotStore = useSlotStore();
    const {slots} = storeToRefs(slotStore);
    if (slots.value) {
        const currentSlotResult = slots.value.find((candidate) => candidate._id === props.slotId);
    return currentSlotResult;
    }
    else {
        return null;
    }
})

const toggleModalShow = async () => {
    // Disable
    if (isArt.value === false) {
        return;
    }

    modalShow.value = !modalShow.value;
    if (modalShow.value) {
        enabled_default = currentSlot.value.enabled;
    }
}

const save = async () => {
    const slotStore = useSlotStore();
    const updatedSlot = await slotStore.updateSlot(props.slotId, {enabled: currentSlot.value.enabled});
    currentSlot.value.enabled = updatedSlot.enabled;
}

const cancel = ()=> {
    currentSlot.value.enabled = enabled_default;
}
</script>