<template>
    <div class="compensatedRoot">
        <LineChart class="compensatedChart" :chartdata="chartData" :options="chartOption" :plugins="[LinePlugin]" />
        <div>
            <b-form-group v-for="(sensor, index) in sensorsSelected" style="margin-bottom: 5px;" @change="valueChanged">
                <div class="row">
                    <div class="col-2" style="display: flex;">
                        <b-form-checkbox v-model="sensor.selected">
                        </b-form-checkbox>
                        <p class="subtxt">{{ sensor.text }}</p>
                    </div>
                    <div class="col-2" style="display: flex;">
                        <p class="subtxt">K1:</p>
                        <b-form-input type="number" v-model="sensor.k1" :step="0.1"></b-form-input>
                    </div>
                    <div class="col-2" style="display: flex;">
                        <p class="subtxt">K2:</p>
                        <b-form-input type="number" v-model="sensor.k2" :step="0.1"></b-form-input>
                    </div>
                    <div class="col-2" style="display: flex;">
                        <p class="subtxt">C1:</p>
                        <b-form-input type="number" v-model="sensor.c1"></b-form-input>
                    </div>
                    <div class="col-2" style="display: flex;">
                        <p class="subtxt">C2:</p>
                        <b-form-input type="number" v-model="sensor.c2"></b-form-input>
                    </div>
                    <div class="col-2" style="display: flex;">
                        <p class="subtxt">T1:</p>
                        <b-form-input type="number" v-model="sensor.t1"></b-form-input>
                        <p class="subtxt">Comp Variance: {{ sensor.variance }}</p>
                    </div>
                </div>
            </b-form-group>
            <div class="row">
                <div class="col-2" style="display: flex;">
                    <p class="subtxt">Max:</p>
                    <b-form-input type="number" v-model="maxY"></b-form-input>
                </div>
                <div class="col-2" style="display: flex;">
                    <p class="subtxt">Min:</p>
                    <b-form-input type="number" v-model="minY"></b-form-input>
                </div>
                <div class="col-3" style="display: flex;">
                    <p class="subtxt">Start Date:</p>
                    <Datepicker v-model="startDate"></Datepicker>
                </div>  
                <div class="col-3" style="display: flex;">
                    <p class="subtxt">End Date:</p>
                    <Datepicker v-model="endDate"></Datepicker>
                </div>     
                <div class="col-2" style="display: flex;">
                    <b-btn class="customChartButton" style="backgroundColor:#005FBE; cursor: pointer; border:2px;"
                    @click="applyDate()">Apply date</b-btn>
                </div>     
            </div>
            <div class="align-content-center justify-content-center row" style="height: 40px;">
                <b-btn class="customChartButton" style="backgroundColor:#005FBE; cursor: pointer;"
                    @click="refresh(true)">Refresh</b-btn>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSlotStore } from "../stores/slotStore";
import { useDurationStore } from "../stores/durationStore";
import { usePreferenceStore } from "../stores/preferenceStore";
import { getDefaultOptions, getDataset, getCompensatedDataset, getSnapshotGps} from "../utilities/slotUtilities";
import LineChart from "../components/LineChart.vue";
import LinePlugin from "../plugins/LinePlugin";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import { ref } from "vue";
import { storeToRefs } from "pinia";

dayjs.extend(utc);
dayjs.extend(tz);

const slotStore = useSlotStore();
const preferenceStore = usePreferenceStore();
const durationStore = useDurationStore();
const chartData = ref({
    labels: [],
    datasets: []
});

let startDate = ref(null);
let endDate = ref(null);

await initializeSlot();

const { preference } = storeToRefs(preferenceStore);

// Note: variance is read-only (computed from the comp data)
const sensorsSelected = preferenceStore.getSensorSelected();

const defaultMaxY = preference.value.options.maxComp === '' || preference.value.options.maxComp === null ? undefined : parseFloat(preference.value.options.maxComp);
const defaultMinY = preference.value.options.minComp === '' || preference.value.options.minComp === null ? undefined : parseFloat(preference.value.options.minComp);
let maxY = ref(defaultMaxY);
let minY = ref(defaultMinY);

const chartOption = getDefaultOptions();
chartOption.scales.y_axis_1.max = maxY.value;
chartOption.scales.y_axis_1.min = minY.value;
chartOption.scales.y_axis_2.max = undefined;

chartOption.plugins.title = {
    display: true,
    color: "#FFFFFF",
    font: { weight: 'bold' },
    text: "",
};

await refresh(false);

async function initializeSlot() {    
    const slotId = getSlotId();
    const {lat, long} = await getSnapshotGps(slotId);
    var tzPayload = {
        lat: lat,
        lng: long,
    }; 

    await slotStore.fetchTimeZone(tzPayload);
    const deviceTimeZone = slotStore.deviceTimeZone;
    startDate.value = new Date(durationStore.startDateTime);
    endDate.value = new Date(durationStore.endDateTime);
    const startTimeUtc = dayjs.tz(durationStore.startDateTime, deviceTimeZone.timeZoneId);
    const endTimeUtc = dayjs.tz(durationStore.endDateTime, deviceTimeZone.timeZoneId);
    const payload = {
        slotId: slotId,
        startDate: startTimeUtc.toISOString(),
        endDate: endTimeUtc.toISOString(),
    };

    await Promise.all([

        slotStore.fetchDeviceHistory(payload),
    ]);

 
    await preferenceStore.fetchPreference();
    await preferenceStore.fetchCompPreference();
}

function dev(arr){
      // Creating the mean with Array.reduce
      let mean = arr.reduce((acc, curr)=>{
        return acc + curr
      }, 0) / arr.length;
       
      // Assigning (value - mean) ^ 2 to every array item
      arr = arr.map((k)=>{
        return (k - mean) ** 2
      })
       
      // Calculating the sum of updated array
     let sum = arr.reduce((acc, curr)=> acc + curr, 0);
      
     // Calculating the variance
     let variance = sum / arr.length
      
     // Returning the standard deviation
     return Math.sqrt(sum / arr.length)
    }

// comSensorName is typically "waterTemp"
function computeCompensatedDataset(deviceHistory, sensorName, yAxisID, compSensorName) {
    const dataset = getCompensatedDataset(sensorsSelected, deviceHistory, sensorName, yAxisID, compSensorName);
    const device = slotStore.currentSlot;
    chartOption.plugins.title.text = device.name;
    return dataset;
}

function computeChartData() {
    const compSensorName = 'waterTemp';
    const deviceHistory = slotStore.deviceHistory;
    const deviceTimeZone = slotStore.deviceTimeZone;
    if (deviceHistory && deviceHistory.sensors && deviceHistory.sensors[0].history.length > 0) {
        const utI = deviceHistory.sensors.findIndex((x) => x.sensorName == "utcTime");
        const labels = deviceHistory.sensors[utI].history.map((timestamp) => dayjs.tz(timestamp.val * 1000, deviceTimeZone.timeZoneId).format("M/DD/YY h:mm a")).reverse();
        // Compute chartData
        const datasets = [];
        // Always populate waterTemp
        const waterTempDataset = getDataset(deviceHistory, compSensorName, 'y_axis_2');
        datasets.push(waterTempDataset);

        deviceHistory.sensors.forEach((sensor) => {
            const sensorName = sensor.sensorName;
            const currentSensor = sensorsSelected.find((item) => item.sensor === sensorName);
            if (sensorsSelected.some((item) => item.sensor === sensorName && item.selected)) {
                const dataset = getDataset(deviceHistory, sensorName, 'y_axis_1');
                datasets.push(dataset);
                const compDataset = computeCompensatedDataset(deviceHistory, sensorName, 'y_axis_1', compSensorName);
                currentSensor.variance = dev(compDataset.data).toFixed(2);
                datasets.push(compDataset);
            }
            else {
                if (currentSensor) {
                    currentSensor.variance = NaN;
                }
            }
        })

        return {
            labels,
            datasets
        }
    }
    else {
        return {
            labels: [],
            datasets: []
        }
    }
}

function valueChanged(updatedVal) {
    refresh(true);
}

function getSlotId() {
    var url = window.location.href;
    const parts = url.split('/');
    const slotId = parts[parts.length - 2];
    return slotId;
}

async function applyDate() {
    durationStore.updateStartDateTime(startDate.value);
    durationStore.updateEndDateTime(endDate.value);
    const slotId = getSlotId();
    const payload = {
        slotId: slotId,
        startDate: startDate.value.toISOString(),
        endDate: endDate.value.toISOString(),
    };

    await Promise.all([
        slotStore.fetchDeviceSnapshot(),
        slotStore.fetchDeviceHistory(payload),
    ]);

    await refresh(true);
}

async function refresh(dirty) {
    chartData.value = computeChartData();
    if (maxY.value === "" || maxY.value === undefined) {
        chartOption.scales.y_axis_1.max = undefined;
    }
    else {
        const maxSettingValue = parseFloat(maxY.value);
        chartOption.scales.y_axis_1.max = maxSettingValue;
    }

    if (minY.value === "" || minY.value === undefined) {
        chartOption.scales.y_axis_1.min = undefined;
    }
    else {
        const minSettingValue = parseFloat(minY.value);
        chartOption.scales.y_axis_1.min = minSettingValue;
    }

    if (dirty) {
        // Update preference
        const patchBody = {
            phycocyaninK1comp: parseFloat(sensorsSelected[0].k1),
            phycocyaninK2comp: parseFloat(sensorsSelected[0].k2),
            phycocyaninSelectedcomp: sensorsSelected[0].selected,
            phycocyaninC1comp: parseFloat(sensorsSelected[0].c1),
            phycocyaninC2comp: parseFloat(sensorsSelected[0].c2),
            phycocyaninT1: parseFloat(sensorsSelected[0].t1),

            chlorAK1comp: parseFloat(sensorsSelected[1].k1),
            chlorAK2comp: parseFloat(sensorsSelected[1].k2),
            chlorASelectedcomp: sensorsSelected[1].selected,
            chlorAC1comp: parseFloat(sensorsSelected[1].c1),
            chlorAC2comp: parseFloat(sensorsSelected[1].c2),
            chlorAT1: parseFloat(sensorsSelected[1].t1),

            turbidityK1comp: parseFloat(sensorsSelected[2].k1),
            turbidityK2comp: parseFloat(sensorsSelected[2].k2),
            turbiditySelectedcomp: sensorsSelected[2].selected,
            turbidityC1comp: parseFloat(sensorsSelected[2].c1),
            turbidityC2comp: parseFloat(sensorsSelected[2].c2),
            turbidityT1: parseFloat(sensorsSelected[2].t1),
            minComp: minY.value,
            maxComp: maxY.value
        }

        await preferenceStore.updateCompPreference(patchBody);
    }
}

</script>
<style scoped>
.compensatedRoot {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.compensatedChart {
    flex: 1;
    min-height: 60%;
}

.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>