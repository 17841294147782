import { defineStore } from "pinia";
import {ref} from 'vue';

export const useDurationStore = defineStore('duration', () => {
  const startDateTime = ref(Date.now() - import.meta.env.VITE_APP_HoursBack * 3600 * 1000);
  const endDateTime = ref(Date.now()); 

  async function updateStartDateTime(val) {
    startDateTime.value = val;
  }

  async function updateEndDateTime(val) {
    endDateTime.value = val;
  }

  return {
    startDateTime,
    endDateTime,
    updateStartDateTime,
    updateEndDateTime
  }
});