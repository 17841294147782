<template>
    <img :src="logoImage" />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import {getUserLogo, getUserLogoOrganizationId} from "../utilities/userUtilities";

function getLogoPath(organizationId, userLogo) {
    if (organizationId) {
        return `/assets/org/${organizationId}.svg`;
    }
    else if (userLogo) {
        return `/assets/org/${userLogo}`;
    }
    else {
        return `/assets/ART_Logo_Linear.png`;
    }
}

const logoImage = computed(() => {
    const userLogoOrganizationId = getUserLogoOrganizationId();
    const userLogo = getUserLogo();
    return getLogoPath(userLogoOrganizationId, userLogo);
})

</script>

<style scoped lang="scss">
img {
    cursor: pointer;
}
</style>