<template>
  <div v-if="SLOT" >
    <Header></Header>
    <!-- device name -->
    <div class="row defaultRow">
      <div class="card" style="width: 100%">
        <h4 style="color:#ffffff; text-align: center; margin-top:15px;">{{ currentSlotName }}</h4>
      </div>
    </div>
    <div class="row defaultRow" style="margin-top:0px">
      <div class="col-12 col-md-6 " style="padding:0">
        <div class="card" style="height: 213px;">
          <b-row>
            <p class="hdrtxt">Device Last Online: {{ lastHeard }}</p>
          </b-row>
          <b-row>
            <img src="/assets/icons/last.png" style="height:24px; width: unset;" />
            <p class="subtxt" style="width: unset;">Last Known Location: {{ lastLat }},{{ lastLong }}</p>
          </b-row>
          <b-row style="margin-bottom:10px;">
            <img src="/assets/icons/default.png" style="height:24px; width: unset;" />
            <p class="subtxt" style="width: unset;">
              Default GPS: {{ gpsLat }},{{ gpsLong }}
            </p>
          </b-row>
          <b-row class="defaultRow" style="justify-content: center; margin-top:15px;">
            <b-btn class="updateBtn" @click="updateSlotGPS()"> Save Last Known GPS Location </b-btn>
          </b-row>
        </div>
      </div>
      <div class="col-12 col-md-6 " style="padding:0">
        <div class="card">
          <SlotMap style="width: 100%; height: 200px;" :deviceLat="gpsLat" :deviceLong="gpsLong" gpsLastUpdate="" :mapOptions="mapOptions" :slotName="currentSlotName">
          </SlotMap>
        </div>
      </div>
    </div>
    <!-- device trendline -->
    <div class="row defaultRow" style="margin-top:0px">
      <div class="card" style="width: 100%">
        <div>
          <h4 class="hdrtxt">Trendline Properties</h4>
          <div>
            <p class="hdrtxt">Phycocyanin</p>
            <b-row>
              <b-col>
                <p class="subtxt">Alpha</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.pcProperties.alpha"
                  :placeholder="trendlineProperties.pcProperties.alpha.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Light Gain</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.pcProperties.kl"
                  :placeholder="trendlineProperties.pcProperties.kl.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Solar Light</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.pcProperties.maxLight"
                  :placeholder="trendlineProperties.pcProperties.maxLight.toString()"
                ></b-form-input>
              </b-col>
            </b-row>
            <p class="hdrtxt">Phycocyanin Temperature Comp</p>
            <b-row>
              <b-col>
                <p class="subtxt">Alpha</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.pcProperties.alphaTemp"
                  :placeholder="trendlineProperties.pcProperties.alphaTemp.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Light Gain</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.pcProperties.kTemp"
                  :placeholder="trendlineProperties.pcProperties.kTemp.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Water Temp. at Cal.</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.pcProperties.stTemp"
                  :placeholder="trendlineProperties.pcProperties.stTemp.toString()"
                ></b-form-input>
              </b-col>
            </b-row>
            <p class="hdrtxt">Chlorphyll-a</p>
            <b-row>
              <b-col>
                <p class="subtxt">Alpha</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.caProperties.alpha"
                  :placeholder="trendlineProperties.caProperties.alpha.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Light Gain</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.caProperties.kl"
                  :placeholder="trendlineProperties.caProperties.kl.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Solar Light</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.caProperties.maxLight"
                  :placeholder="trendlineProperties.caProperties.maxLight.toString()"
                ></b-form-input>
              </b-col>
            </b-row>
            <p class="hdrtxt">Turbidity</p>
            <b-row>
              <b-col>
                <p class="subtxt">Alpha</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.tuProperties.alpha"
                  :placeholder="trendlineProperties.tuProperties.alpha.toString()"
                ></b-form-input>
              </b-col>
            </b-row>
            <p class="hdrtxt">Turbidity Temperature Comp</p>
            <b-row>
              <b-col>
                <p class="subtxt">Alpha</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.tuProperties.alphaTemp"
                  :placeholder="trendlineProperties.tuProperties.alphaTemp.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Light Gain</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.tuProperties.kTemp"
                  :placeholder="trendlineProperties.tuProperties.kTemp.toString()"
                ></b-form-input>
              </b-col>
              <b-col>
                <p class="subtxt">Water Temp. at Cal.</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.tuProperties.stTemp"
                  :placeholder="trendlineProperties.tuProperties.stTemp.toString()"
                ></b-form-input>
              </b-col>
            </b-row>
            <p class="hdrtxt">Water Temperature</p>
            <b-row>
              <b-col>
                <p class="subtxt">Alpha</p>
                <b-form-input
                  size="sm"
                  type="number"
                  v-model.number="trendlineProperties.wtProperties.alpha"
                  :placeholder="trendlineProperties.wtProperties.alpha.toString()"
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <b-row class="defaultRow" style="justify-content: center; margin-top:15px;">
            <b-btn class="updateBtn" @click="updateTrendLineProperties()">
              Save Trendline Data
            </b-btn>
          </b-row>
        </div>

        <!-- slot details -->
      </div>
    </div>
    <!-- user information -->
    <div class="row defaultRow" style="margin-top:0px">
      <div class="card" style="width: 100%">
        <b-row>
          <p class="util-text">Users</p>
        </b-row>
        <b-table
          responsive
          striped
          small
          head-variant="light"
          table-variant="light"
          :items="Users"
        ></b-table>
        <b-row class="defaultRow" style="justify-content: center; margin-top:15px;">
          <b-btn class="updateBtn"> Add Users </b-btn>
          <!-- XXX instert "add user" API here -->
        </b-row>
      </div>
    </div>
    <!-- particle functions -->
    <!-- XXX instert Particle function calls here -->
    <div class="row defaultRow">
      <div class="card" style="width: 100%">
        <b-row>
          <h4 class="hdrtxt">Particle Functions</h4>
        </b-row>
        <div>
          <!-- Already functional-->
          <label class="subtxt"> Place Device in Stay Awake Mins</label>
          <input type="checkbox" v-model="SLOT.wakeRequest" @change="updateAwake($event)" />
        </div>
        <!-- SAM Function-->
        <b-input-group prepend="Stay Awake Minutes" style="max-width:75%;">
          <b-form-input v-model="samSelected" :placeholder="samSelected"></b-form-input>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <!-- Function Return goes here -->
        <b-row>
          <label class="subtxt"> {{ samReturn }}</label>
        </b-row>

        <!-- report Values -->
        <b-input-group prepend="reportValues" style="max-width:75%;">
          <b-form-select
            v-model="reportValueSelected"
            :options="reportValueOptions"
          ></b-form-select>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ reportValueReturn }}</label>
        </b-row>
        <!-- publish tracker data particle -->
        <b-input-group prepend="publishTrackerData" style="max-width:75%;">
          <b-form-input
            v-model="publishTrackerDataSelected"
            :placeholder="publishTrackerDataSelected"
          ></b-form-input>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ publishTrackerDataReturn }}</label>
        </b-row>
        <!-- init particle -->
        <b-input-group prepend="initParticle" style="max-width:75%;">
          <b-form-input
            v-model="initParticleSelected"
            :placeholder="initParticleSelected"
          ></b-form-input>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ initParticleReturn }}</label>
        </b-row>
        <!-- CAL RGB -->
        <b-input-group prepend="calRGB" style="max-width:75%;">
          <b-form-select v-model="calRGBSelected" :options="calRGBOptions"></b-form-select>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ calRGBReturn }}</label>
        </b-row>
        <!-- cal solar low -->
        <b-input-group prepend="Cal Solar Low" style="max-width:75%;">
          <b-form-input v-model="solarLowSelected" :placeholder="solarLowSelected"></b-form-input>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ solarLowReturn }}</label>
        </b-row>
        <!-- cal solar high -->
        <b-input-group prepend="Cal Solar High" style="max-width:75%;">
          <b-form-input v-model="solarHighSelected" :placeholder="solarHighSelected"></b-form-input>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ solarHighReturn }}</label>
        </b-row>
        <!-- cal buoy temperature -->
        <b-input-group prepend="Cal Buoy Temp." style="max-width:75%;">
          <b-form-input v-model="buoyTempSelected" :placeholder="buoyTempSelected"></b-form-input>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ buoyTempReturn }}</label>
        </b-row>
        <!-- read cal counts -->
        <b-input-group prepend="readCalCounts" style="max-width:75%;">
          <b-form-select
            v-model="readCalCountsSelected"
            :options="readCalCountsOptions"
          ></b-form-select>
          <b-input-group-append>
            <b-btn class="updateBtn"> Call Function</b-btn>
          </b-input-group-append>
        </b-input-group>
        <b-row>
          <label class="subtxt"> {{ readCalCountsReturn }}</label>
        </b-row>
      </div>
    </div>
    <!-- options -->
    <div class="row defaultRow">
      <div class="card" style="width: 100%">
        <b-icon
          icon="house"
          @click="goHome()"
          class="util-tools"
          style="float:right"
          v-b-popover.hover.top="'return home'"
        ></b-icon>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<style lang="scss">
.hdrtxt {
  color: #ffffff;
  padding: 5px;
  margin-bottom: 0;
  font-size: 1rem;
}
.subtxt {
  display: inline-block;
  color: #ffffff;
  padding: 5px;
  margin-bottom: 0;
  font-size: 0.8rem;
}
.updateBtn {
  background-color: #064ea1;
  border: 2px solid #cecece;
  font-size: 0.8rem;
  color: white;
  width: unset;
}
</style>

<script lang="ts">
import router from "../router";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import {authRoot} from "../vue-client";
import { useSlotStore } from "../stores/slotStore";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import { storeToRefs } from "pinia";
import { useAuthStore } from "../stores/authStore";
import SlotMap from "../components/SlotMap.vue";
dayjs.extend(utc);
dayjs.extend(tz);

export default {
  components: {
    Header,
    Footer,
    SlotMap
  },
  data() {
    return {
      trendlineProperties: this.resetTrendline(),
      SNAPSHOT: [],
      Users: [],
      zoomLevel: 8,
      slotName: "",
      lastHeard: "",
      lastLat: "",
      lastLong: "",
      window_open: false,
      hover:false,
      infoWindow: { lat: 0, lng: 0 },
      gmapName: "",
      gmapId: "",
      gpsData: [
        {
          name: "",
          latLong: {
            lat: 0.0,
            lng: 0.0,
          },
        },
      ],
      mapOptions: {
        gestureHandling: "cooperative",
      },
      // particle function report values
      samSelected: "33",
      samReturn: "", // store text returned from function call
      initParticleSelected: "14",
      initParticleReturn: "",
      publishTrackerDataSelected: "go",
      publishTrackerDataReturn: "",
      solarLowSelected: "0",
      solarLowReturn: "", // store text returned from function call
      solarHighSelected: "25000",
      solarHighReturn: "", // store text returned from function call
      buoyTempSelected: "21",
      buoyTempReturn: "", // store text returned from function call
      reportValueSelected: "check1",
      reportValueOptions: [
        { value: "ohc", text: "ohc" },
        { value: "oh", text: "oh" },
        { value: "check1", text: "check1" },
        { value: "nh", text: "nh" },
        { value: "gps", text: "gps" },
      ],
      reportValueReturn: "", // store text returned from function call
      calRGBSelected: "0",
      calRGBOptions: [
        { value: "0", text: "low cal RGB" },
        { value: "1", text: "high cal Green" },
        { value: "2", text: "high cal Blue" },
        { value: "3", text: "high cal Red" },
      ],
      calRGBReturn: "", // store text returned from function call
      readCalCountsSelected: "all",
      readCalCountsOptions: [
        { value: "all", text: "all cal data" },
        { value: "red", text: "red cals" },
        { value: "green", text: "green cals" },
        { value: "blue", text: "blue cals" },
      ],
      readCalCountsReturn: "", // store text returned from function call
    };
  },
  async mounted(){
    const currentSlot = this.SLOT.value;
      this.slotName = currentSlot.name;
      this.trendlineProperties = currentSlot.trendlineProperties;
      const [snapshot, gpsData, users] = await Promise.all([
        this.getSlotData(),
        this.getGPS(),
        this.getUsers(),
      ]);
      this.SNAPSHOT = snapshot;
      this.gpsData = gpsData;
      this.Users = users;
      if (snapshot && snapshot.deviceProps) {
        this.lastHeard = dayjs(
          snapshot.deviceProps.find((x) => x.name == "utcTime").createdOn
        ).format("M/DD/YY HH:mm");
        this.lastLat = snapshot.deviceProps.find((x) => x.name == "latitude").result.toString();
        this.lastLong = snapshot.deviceProps.find((x) => x.name == "longitude").result.toString();
      } else {
        this.lastHeard = "unknown";
        this.lastLat = "unknown";
        this.lastLong = "unknown";
      }
  },
  computed: {
    SLOT: {
            get: function () {
              const slotStore = useSlotStore();
              const {currentSlot} = storeToRefs(slotStore);
              return currentSlot;
            }
        },
    gpsLat: {
        get: function() {
          const slotStore = useSlotStore();
          const {currentSlot} = storeToRefs(slotStore);
          return currentSlot.value.gpsLat;
        }
    },
    gpsLong: {
        get: function() {
          const slotStore = useSlotStore();
          const {currentSlot} = storeToRefs(slotStore);
          return currentSlot.value.gpsLong;
        }
    },
    currentSlotName: {
      get: function() {
          const slotStore = useSlotStore();
          const {currentSlot} = storeToRefs(slotStore);
          return currentSlot.value.name;
        }
    }
  },
  methods: {
    async getSlotData() {
      const slotStore = useSlotStore();
      await slotStore.fetchDeviceSnapshot();
      const {deviceSnapshot} = storeToRefs(slotStore);
      return deviceSnapshot.value;
    },
    goHome() {
      this.appStartSwitch = false;
      router.push({ name: "home" });
    },
    goToDashboard() {
      this.appStartSwitch = true;
      router.push({ name: "home" });
    },
    async getUsers() {
      const slotStore = useSlotStore();
      const {currentSlot} = storeToRefs(slotStore);
      let device = currentSlot.value;
      let users = device.viewer_ids;
      // var userInfo = [];
      var userInfo = await Promise.all(
        users.map(async (x) => {
          return await this.fectchUserData(x);
        })
      );
      var u = userInfo.map((y) => {
        return {
          firstName: y.firstName,
          lastName: y.lastName,
          email: y.email,
        };
      });
      return u;
    },
    async fectchUserData(userId) {
      const authStore = useAuthStore();
      const {authUser} = storeToRefs(authStore);
      let authString = "Bearer " + authUser.value.authToken;
      const response = await fetch(`${authRoot}/users/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authString,
        },
      });
      return await response.json();
    },
    getGPS() {
      var lat = 0.0;
      var lng = 0.0;
      var path =
        "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z";
      const currentSlot = this.SLOT;
      var gpsData = [
        {
          name: "default location",
          latLong: {
            lat: currentSlot.value.gpsLat,
            lng: currentSlot.value.gpsLong,
          },
          icon: {
            path: path,
            fillColor: "#ab090c",
            fillOpacity: 0.9,
            scale: 1.5,
            anchor: { x: 12, y: 24 },
          },
        },
      ];

      const slotStore = useSlotStore();
      const {deviceSnapshot} = storeToRefs(slotStore);
      var snapshot = deviceSnapshot.value;

      if (snapshot && snapshot.deviceProps) {
        lat = snapshot.deviceProps.find((x) => x.name == "latitude").result;
        lng = snapshot.deviceProps.find((x) => x.name == "longitude").result;
        gpsData.push({
          name: "last location",
          latLong: {
            lat: lat,
            lng: lng,
          },
          icon: {
            path: path,
            fillColor: "#064ea1",
            fillOpacity: 0.9,
            scale: 1.5,
            anchor: { x: 10, y: 20 },
          },
        });
        if (Math.abs(currentSlot.value.gpsLat - lat) < 0.2) {
          this.zoomLevel = 18;
        } else if (Math.abs(currentSlot.value.gpsLat - lat) < 0.5) {
          this.zoomLevel = 15;
        }
      }
      this.infoWindow = {
        lat: lat,
        lng: lng,
      };
      return gpsData;
    },
    openWindow(dat) {
      this.infoWindow = { lat: parseFloat(dat.latLong.lat), lng: parseFloat(dat.latLong.lng) };
      (this.gmapName = dat.name), (this.gmapId = dat.id), (this.window_open = true);
      // console.log(this.infoWindow);
    },
    async updateAwake() {
      const currentSlot = this.SlOT;
      await slotStore.updateSlot(currentSlot.value._id, { wakeRequest: currentSlot.value.wakeRequest });
      return;
    },
    async updateSlotGPS() {
      const slotStore = useSlotStore();
      const currentSlot = this.SlOT;
      await slotStore.updateSlot(currentSlot.value._id, {
        gpsLat: this.lastLat,
        gpsLong: this.lastLong,
      });
      return;
    },
    async updateTrendLineProperties() {
      let trendPayload = this.trendlineProperties;
      const slotStore = useSlotStore();
      await slotStore.updateSlot(currentSlot.value._id, { trendlineProperties: trendPayload });
      return;
    },
    resetTrendline() {
      const data = {
        pcProperties: {
          kl: 0.01, // vertical shift
          alpha: 0.99, // 0 - 1 lowpass filtering
          shift: 1, // shift to offset lowpass
          maxLight: 200, // shift for solar light in lightcomp
          alphaTemp: 0.99, // 0 - 1 lowpass filtering
          kTemp: 0.02, // vertical shift
          stTemp: 25, // temperature at calibration
          calVal: 710, // value at high calibration
          useCal: false, // control whether or not calVal is used
        },
        caProperties: {
          kl: 0.05,
          alpha: 0.95,
          shift: 2,
          maxLight: 400,
        },
        tuProperties: {
          kl: 0.01,
          alpha: 0.95,
          shift: 2,
          alphaTemp: 0.8,
          kTemp: -0.5,
          stTemp: 22,
          calVal: 50,
          useCal: false,
        },
        wtProperties: {
          alpha: 0.95,
          shift: 2,
        },
        phProperties: {
          alpha: 0.95,
          shift: 2,
        },    
        doProperties: {
          alpha: 0.95,
          shift: 2,
        }, 
        psProperties: {
          alpha: 0.95,
          shift: 2,
        },
      };
      return data;
    },
  },
};
</script>
