<template>
  <div class="header">
    <b-navbar class="nav" toggleable to="/">
        <Logo class="logo" v-on:click="navigate('/')"></Logo>
      <b-nav-item v-if="auth">
        <div class="time">{{ timestamp }}</div>
      </b-nav-item>
      <b-dropdown v-if="auth" no-caret right style="align-content: center; background: var(--cardBackground)">
        <template #button-content>
          <BootstrapIcon class="menuIcon" icon="list" style="display:flex; "></BootstrapIcon>
        </template>
        <b-dropdown-item v-on:click="userSettingsModal = !userSettingsModal">
          <BootstrapIcon icon="person" class="menuItem"> </BootstrapIcon>
          <p class="name">Account</p>
        </b-dropdown-item>
        <b-dropdown-item v-on:click="openhelp">
        <!--a href="https://drive.google.com/drive/folders/12bUyB0iAKUc-D4thPktYoWt-pZF-IZik?usp=sharing"-->
          <BootstrapIcon icon="book" class="menuItem"> </BootstrapIcon>
          <p class="name">Help</p>
        </b-dropdown-item>    
        <b-dropdown-item v-on:click="logout">
          <BootstrapIcon icon="power" class="menuItem"> </BootstrapIcon>
          <p class="name">Logout</p>
        </b-dropdown-item>
      </b-dropdown>
    </b-navbar>
    <b-modal v-model="userSettingsModal" :hide-footer="hideEmail" title="Account Settings" @ok="updateUserInfo()" ok-title="update settings">
      <div class="editUnit">
        <h6> {{ userSettings.firstName }} {{ userSettings.lastName }} </h6>
        <h6> {{ userSettings.email }} </h6>
      </div>
      <div v-if="!hideEmail" class="editUnit">
        <p class="unitText"> Enable Sensor Alert Notications: </p>
        <p class="unitText"> No </p>
        <b-form-checkbox switch size="lg" v-model="userSettings.allowAlertEmail" class="deg-switch">
        </b-form-checkbox>
        <p class="unitText"> Yes </p>
      </div>
      <div v-if="!hideEmail" class="editUnit">
        <p class="unitText"> Enable Weekly Summary Email: </p>
        <p class="unitText"> No </p>
        <b-form-checkbox switch size="lg" v-model="userSettings.allowSummaryEmail" class="deg-switch">
        </b-form-checkbox>
        <p class="unitText"> Yes </p>
      </div>


    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  padding-top: 10px;
  padding-bottom: 3px;
  height: 60px;
  width: 100%;
  background-color: rgb(50, 59, 77);

  .nav {
    width: 100%;
    flex-wrap: nowrap;
  }

  .name {
    color: #000000;
  }

  .time {
    clear: none;
    margin: auto;
    font-size: 1em;
    font-weight: 100;
    color: #d7d7d8;
    line-height: 40px;
  }

  .logo {
    display: flex;
    float: left;
    margin-left: 1%;
    max-width: 150px;
  }

  .menuIcon {
    color: #ffffff;
    width: 40px;
  }

  .menuItem {
    color: #282d38;
    height: 25px;
    width: 25px;
    // margin: 3px 1px 3px 3px;
  }
}

@media (max-width: (425px)) {
  .time {
    display: none;
  }

  .logout {
    margin-left: auto;
  }
}
</style>

<script>
import router from '../router';
import { authRoot } from "../vue-client";
import { useAuthStore } from '../stores/authStore';
import Logo from './Logo.vue';
import {getUserLogoOrganizationId, getUserLogo} from "../utilities/userUtilities";
export default {
  name: "Header",
  components: {
    Logo
  },
  computed: {
    auth: function () {
      const authStore = useAuthStore();
      return authStore.authUser;
    },
    hideEmail: function() {
      const userLogoOrganizationId = getUserLogoOrganizationId();
      const userLogo = getUserLogo();
      return !!userLogoOrganizationId || !!userLogo;
    },
    userSettings: function () {
      const authStore = useAuthStore();
      const info = {
        firstName: "",
        lastName: "",
        email: "",
        allowAlertEmail: false,
        allowSummaryEmail: false,
      };

      if (authStore?.authUser?.account) {
        var user = authStore.authUser;
        info.firstName = user.firstName;
        info.lastName = user.lastName;
        info.email = user.email;
        info.allowAlertEmail = user.allowAlertEmail;
        info.allowSummaryEmail = user.allowSummaryEmail;
      }

      return info;
    }
  },
  data() {
    return {
      timestamp: "",
      userSettingsModal: false
    };
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    logout: async function () {
      const authStore = useAuthStore();
      const hintOrganizationId = await authStore.logout();
      if (!hintOrganizationId) {
        router.push({ name: "signIn" });
      }
      else {
        router.push({ name: "signIn", query: {orgId: hintOrganizationId}}); 
      }
    },
    openhelp: async function(){
      window.open("https://drive.google.com/drive/folders/12bUyB0iAKUc-D4thPktYoWt-pZF-IZik?usp=sharing", '_blank','noreferrer');
    }
    ,
    navigate: function (url) {
      router.push(url);
    },

    getNow: function () {
      const today = new Date();
      const date =
        today.getMonth() +
        1 +
        "/" +
        today.getDate() +
        "/" +
        today.getFullYear();
      let hours = today.getHours();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      if (hours == 0) {
        hours = 12;
      }
      let minutes = today.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      const time = hours + ":" + minutes;
      const dateTime = date + " " + time + "" + ampm;
      this.timestamp = dateTime;
    },
    async updateUserInfo() {
      var info = {};
      info.allowAlertEmail = this.userSettings.allowAlertEmail;
      info.allowSummaryEmail = this.userSettings.allowSummaryEmail;
      const inputData = info;
      let authString = 'Bearer ' + this.auth.authToken;
      const response = await fetch(`${authRoot}/users/${this.auth._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': authString
          },
          body: JSON.stringify(inputData),
        });
      await response.json();
      return;
    }

  },
};
</script>
