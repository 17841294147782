import { IFeature } from "./feature-interface";
import { DoPsFeature } from "./do-ps-feature";
import { PhFeature } from "./ph-feature";

const constructors: object = {};
(constructors as any)['2'] = DoPsFeature;
(constructors as any)['3'] = PhFeature;
export class FeatureManager {
    public static CreateFeature(feature: number): IFeature {
        return new (constructors as any)[feature.toString()]();
    }
}