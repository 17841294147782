import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

const LinePlugin = {
    id: "chart-plugin",
    afterDraw: (chart) => {
        //  draw a v line
        if (chart.tooltip._active && chart.tooltip._active.length && chart.scales.y_axis_1) {
            var activePoint = chart.tooltip._active[0],
                ctx = chart.ctx,
                x = activePoint.element.x,
                topY = chart.scales["y_axis_1"].top,
                bottomY = chart.scales["y_axis_1"].bottom;

            // draw line
            ctx.save();
            ctx.lineWidth = 6;
            ctx.borderWidth = 6;
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.strokeStyle = "#07C";
            ctx.stroke();
            ctx.restore();
        }
    },
};
Chart.register(LinePlugin);
Chart.register(zoomPlugin);
export default LinePlugin;