import gql from "graphql-tag";
export const SIGN_IN = gql`
mutation authUser($email: String!, $password: String!) {
  authUser(email: $email, password: $password) {
    _id
    status
    lastLogin
    firstName
    lastName
    email
    role
    authToken
    allowAlertEmail
    allowSummaryEmail
    account {
      _id
    }
  }
}`;
