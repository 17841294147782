import { IFeature } from "../features/feature-interface";
import { FeatureManager } from "../features/feature-manager";
import { ITrackerData } from "./tracker-data";

export class TrackerGroup {
    private _feature: IFeature;
    constructor(private trackerTraces: ITrackerData[], public feature: number, device: any) {
        
        // Compute plot option
        this._feature = FeatureManager.CreateFeature(feature);
        this._feature.initialize(this.trackerTraces, device);
    }

    refresh(device: any) {
        this._feature.initialize(this.trackerTraces, device);
    }

    get PlotData() {
        return this._feature.getPlotData();
    }

    get PlotOptions() {
        return this._feature.getPlotOptions();
    }

    get DonutData() {
        return this._feature.getDonutData();
    }

    get DonutOptions() {
        return this._feature.getDonutOptions();
    }
}