import { ITrackerData } from "../models/tracker-data";
import { IFeature } from "./feature-interface";
import {
    getDefaultOptions,
    getLineChartColor,
    getReportMinMax,
    getAxesMinMaxStep
} from "../utilities/slotUtilities";
import {getColor} from "../utilities/colorUtilities";

export class DoPsFeature implements IFeature {
    private _trackerTraces:  ITrackerData[] = [];
    private _plotData: any = null;
    private _plotOptions: any = null;
    private _donutData: any = null;
    private _donutOptions: any = null;
    initialize(trackerTraces: ITrackerData[], device: any): void {
        this._trackerTraces = trackerTraces;
        this._plotData = {
            labels: this._trackerTraces.find((x) => x.sensorName == "timeString").history,
            datasets: [
                {
                    data: this._trackerTraces.find((x) => x.sensorName == "do").history,
                    fill: false,
                    label: "DO",
                    backgroundColor: getLineChartColor('do'),
                    borderColor: getLineChartColor('do'),
                    borderDash: [2],
                    yAxisID: "y_axis_1"
                },
                {
                    data: this._trackerTraces.find((x) => x.sensorName == "ps").history,
                    fill: false,
                    label: "PS",
                    backgroundColor: getLineChartColor('ps'),
                    borderColor: getLineChartColor('ps'),
                    borderDash: [2],
                    yAxisID: "y_axis_2"
                },        
            ]
        }

        const plotOptions = getDefaultOptions();
        plotOptions.plugins.title.text = 'Dissolved Oxygen & %Sat';
        {
            var minMaxSensor = [];
            this._plotData.datasets[0].data = this._trackerTraces.find((x) => x.sensorName == 'do').history;
            plotOptions.scales.y_axis_1.scaleLabel.labelString = 'do';
            minMaxSensor = getReportMinMax(
                this._trackerTraces.find((x) => x.sensorName == 'do').history,
                0,
                20
            );

            plotOptions.scales.y_axis_1.title = {
                display: true,
                text: "DO (mg/L)",
                color: getLineChartColor('do')
            }

            const sensorAx = getAxesMinMaxStep(minMaxSensor[0], minMaxSensor[1], 10, 0);
            plotOptions.scales.y_axis_1.suggestedMin = sensorAx[0];
            plotOptions.scales.y_axis_1.suggestedMax = sensorAx[1];
            plotOptions.scales.y_axis_1.ticks.stepSize = sensorAx[2];
        }
        {
            var minMaxSensor = [];
            this._plotData.datasets[1].data = this._trackerTraces.find((x) => x.sensorName == 'ps').history;
            plotOptions.scales.y_axis_2.scaleLabel.labelString = 'ps';
            minMaxSensor = getReportMinMax(
                this._trackerTraces.find((x) => x.sensorName == 'ps').history,
                0,
                120
            );

            plotOptions.scales.y_axis_2.title = {
                display: true,
                text: "% Saturation",
                color: getLineChartColor('ps')
            }

            const sensorAx = getAxesMinMaxStep(minMaxSensor[0], minMaxSensor[1], 10, 0);
            plotOptions.scales.y_axis_2.suggestedMin = sensorAx[0];
            plotOptions.scales.y_axis_2.suggestedMax = sensorAx[1];
            plotOptions.scales.y_axis_2.ticks.stepSize = sensorAx[2];
        }   

        this._plotOptions = plotOptions;

        const targetValue = this._trackerTraces.find((x) => x.sensorName == 'do').history[this._trackerTraces.find((x) => x.sensorName == 'do').history.length - 1];
        const alert = device.deviceAlerts.find((x) => x.devicePropName == 'do');
        const high = (alert == undefined) ? 20 : alert.high;
        const low = (alert == undefined) ? 0 : alert.low;
        const maxValue = high;
        const donutData = {
            displayName: 'DO',
            sensorName: 'do',
            units: 'mg/L',
            value: targetValue,
            labels: ['do', 'total'],
            datasets: [
                {
                    label: 'do',
                    backgroundColor: [getColor(targetValue, high, low), "#eaeaea"],
                    data: [
                        targetValue > maxValue ? maxValue : targetValue,
                        maxValue > targetValue ? maxValue - targetValue : 0,
                    ],
                },
            ],
        }
        this._donutData = donutData;

        const donutOption = {
            responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 2.5,
                circumference: 180,
                rotation: -90,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        enabled: false
                    },
                },
                borderWidth: 2
        }

        this._donutOptions = donutOption;    
        
    }

    getPlotData() {
        return this._plotData;
    }

    getPlotOptions() {
        return this._plotOptions;
    }

    getDonutData(): any {
        return this._donutData;
    }

    getDonutOptions(): any {
        return this._donutOptions;   
    }
}