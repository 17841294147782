<template>
    <div v-for="tracker in trackers" class="col-12 col-md-6 cust-col">
        <div class="card chart">
            <LineChart :chartdata="tracker.PlotData" :options="tracker.PlotOptions" :plugins="[LinePlugin]" style="height:300px;"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSlotStore } from "../stores/slotStore";
import { useDurationStore } from "../stores/durationStore";
import LineChart from "./LineChart.vue";
import LinePlugin from "../plugins/LinePlugin";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import tz from 'dayjs/plugin/timezone';
import { ref, watch } from "vue";
import { SensorTracker } from "../models/sensorTracker";
import { storeToRefs } from "pinia";
import {
    getLineChartColor,
    getReportMinMax,
    getAxesMinMaxStep
} from "../utilities/slotUtilities";

// Look-up table
class SensorSetting {
    constructor(chartName, displayName, min, max) {
        this.chartName = chartName;
        this.displayName = displayName;
        this.min = min;
        this.max = max;
    }
}

const sensorSettings = new Map();

// unifF: true for Celcius. false for Fahrenheit
function initializeSensorSettings(unitF) {
    if (unitF === true) {
        sensorSettings.set('precipitationIntensity', new SensorSetting('Precipitation', 'Precipitation (mm/hr)', 0, 10));
        sensorSettings.set('windSpeed', new SensorSetting('Wind Speed', 'Wind Speed (kph)', 0, 36));
    }
    else {
        sensorSettings.set('precipitationIntensity', new SensorSetting('Precipitation', 'Precipitation (in/hr)', 0, 0.4));
        sensorSettings.set('windSpeed', new SensorSetting('Wind Speed', 'Wind Speed (mph)', 0, 22.4));
    }
}

// unifF: true for Celcius. false for Fahrenheit
function computeWindSpeed(valueInMeterPerSecond, unitF) {
    if (unitF === true) {
        return valueInMeterPerSecond * 3.6; // Convert to kph
    }
    else {
        return valueInMeterPerSecond * 2.23694; // Convert to miles per hour
    }
}

// unifF: true for Celcius. false for Fahrenheit
function computePrecipitationIntensity(valueInMilimeterPerHour, unitF) {
    if (unitF === true) {
        return valueInMilimeterPerHour;
    }
    else {
        return valueInMilimeterPerHour * 0.0393701; // Convert to inches per hour
    }
}


dayjs.extend(utc);
dayjs.extend(tz);

const props = defineProps({
    sensorNames: { type: Array, required: true },
    unitF: {type: Boolean, required: true} // unifF: true for Celcius. false for Fahrenheit
})

const trackers = ref([]); // SensorTracker

watch(() => props.sensorNames,
    async (newSensorNames, oldSensorNames) => {
        await initializeSlot(newSensorNames);
    }
);

watch(() => props.unitF,
    async (newUnitF, oldUnitF) => {
        await initializeSlot(props.sensorNames);
    }
);


const durationStore = useDurationStore();

function getSensorDisplayName(sensorName) {
    if (sensorSettings.has(sensorName)) {
        return sensorSettings.get(sensorName).displayName;
    }
    else {
        return sensorName;
    }
}

function getSensorChartName(sensorName) {
    if (sensorSettings.has(sensorName)) {
        return sensorSettings.get(sensorName).chartName;
    }
    else {
        return sensorName;
    }
}

async function initializeSlot(sensorNames) {
    /*
    const preferenceStore = usePreferenceStore();
    const { preference } = storeToRefs(preferenceStore);
    this.unitF = preference.value.options.unitF;
    */

    initializeSensorSettings(props.unitF);

    trackers.value = [];
    if (sensorNames.length > 0) {
        const slotId = getSlotId();
        const slotStore = useSlotStore();
        const deviceTimeZone = slotStore.deviceTimeZone;
        const startTimeUtc = dayjs.tz(durationStore.startDateTime, deviceTimeZone.timeZoneId);
        const endTimeUtc = dayjs.tz(durationStore.endDateTime, deviceTimeZone.timeZoneId);
        const payload = {
            startDate: startTimeUtc.toISOString(),
            endDate: endTimeUtc.toISOString(),
            sensors: sensorNames
        };

        // TODO: fetch time series
        await slotStore.fetchTimeSeries(slotId, payload);
        const { timeSeriesHistory } = storeToRefs(slotStore);
        sensorNames.forEach((sensorName) => {
            const sensor = timeSeriesHistory.value.sensors.find((item) => item.sensorName === sensorName);
            const labelsUtc = sensor.history.map((item) => item.timeStamp);
            const labels = labelsUtc.map((item) => dayjs.tz(new Date(item), deviceTimeZone.timeZoneId).format("M/DD/YY h:mm a"));
            const values = sensor.history.map((item) => item.val);
            if (sensorName === 'windSpeed') {
                // Scale it from m/s to kph
                values.forEach((item, index) => {
                    values[index] = computeWindSpeed(item, props.unitF);
                })
            }
            else if (sensorName === 'precipitationIntensity') {
                // Scale it from mm/hr to in/hr
                values.forEach((item, index) => {
                    values[index] = computePrecipitationIntensity(item, props.unitF);
                })
            }
            
            const sensorDiplayName = getSensorDisplayName(sensorName);
            const sensorChartName = getSensorChartName(sensorName);
            const dataset = {
                data: values,
                fill: true,
                label: sensorDiplayName,
                backgroundColor: getLineChartColor(sensorName),
                borderColor: getLineChartColor(sensorName),
                borderWidth: 1,
                yAxisID: 'y_axis_1',
                hidden: false
            };
            const sensorMinRange = sensorSettings.get(sensorName).min;
            const sensorMaxRange = sensorSettings.get(sensorName).max;
            const minMax = getReportMinMax(values, sensorMinRange, sensorMaxRange);
            const minMaxStep = getAxesMinMaxStep(minMax[0], minMax[1], 10, 0);
            const tracker = new SensorTracker(sensorChartName, labels, [dataset], minMaxStep);
            trackers.value.push(tracker);
        })
    }
}

function getSlotId() {
    var url = window.location.href;
    const parts = url.split('/');
    const slotId = parts[parts.length - 1];
    return slotId;
}

</script>
<style scoped>

.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>