import { getDefaultOptions} from "../utilities/slotUtilities";

// TimeSeries
class SensorPlot {
    constructor(labels, datasets) {
        this.labels = labels;
        this.datasets = datasets;
    }
}

export class SensorTracker {
    _plotOptions = null;
    _sensorPlot = null;
    constructor(name, labels, datasets, minMaxStep) {
        this._plotOptions = getDefaultOptions();
        this._plotOptions.plugins.title = {
            display: true,
            color: "#FFFFFF",
            font: { weight: 'bold' },
            text: name,
        };
        
        this._plotOptions.scales.y_axis_1.suggestedMin = minMaxStep[0];
        this._plotOptions.scales.y_axis_1.suggestedMax = minMaxStep[1];
        this._plotOptions.scales.y_axis_1.ticks.stepSize = minMaxStep[2];
        this._plotOptions.scales.y_axis_2.display = false;
        this._sensorPlot = new SensorPlot(labels, datasets);
    }

    get PlotData() {
        return this._sensorPlot;
    }

    get PlotOptions() {
        return this._plotOptions;
    }
}