<template>
  <div class="footer">
    <Logo class="logo"></Logo>
  </div>
</template>

<style scoped lang="scss">
  .footer {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    height: 60px;
    // width: 100%;
    background-color: rgb(50, 59, 77);
  }
  .logo {
    max-width: 150px;
  }
</style>

<script lang="ts">
import router from '../router';
import Logo from './Logo.vue';
export default{
  name: "Footer",
  components: {
    Logo
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    logout: function() {
      this.$store.dispatch('auth/logout', {authUser:null});
      router.push({ name: "signIn" });
    },
  },
};
</script>
