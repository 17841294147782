<template>
    <div style="display: inline-block" title="Notes" v-if="isArt">
        <BootstrapIcon icon="pencil-square" small @click="toggleModal()"></BootstrapIcon>
        <b-modal v-model="modalShow" :title="`Note for ${slotName}`" @ok="save">
            <b-form-textarea id="textarea" v-model="text" placeholder="Enter notes..." rows="3" max-rows="6">
            </b-form-textarea>
        </b-modal>
    </div>

</template>

<script setup lang="ts">
import { useNoteStore } from "../stores/noteStore";
import { useAuthStore } from "../stores/authStore";
import { ref, computed } from "vue";

let modalShow = ref(false);
const text = ref('');
const props = defineProps({
    slotId: {
        type: String,
        default: ''
    },
    slotName: {
        type: String,
        default: ''
    }
});

const isArt = computed(() => {
    const authStore = useAuthStore();
    return authStore.isArt;
})

const toggleModal = async () => {
    const noteStore = useNoteStore();
    modalShow.value = !modalShow.value;
    if (modalShow.value) {
        text.value = await noteStore.fetchNote(props.slotId);
    }
}

const save = async () => {
    const noteStore = useNoteStore();
    text.value = await noteStore.updateNote(props.slotId, text.value);
}

</script>