import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import {BootstrapVue3} from 'bootstrap-vue-3';
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { apolloProvider } from "./vue-client";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import StoreReset from './plugins/StoreReset'
import { plugin as formKitPlugin, defaultConfig } from '@formkit/vue'
import { createMultiStepPlugin } from '@formkit/addons'
import '@formkit/themes/genesis'
import '@formkit/addons/css/multistep'
const app = createApp(App);

app.component('Datepicker', Datepicker);
app.component('BootstrapIcon', BootstrapIcon);
app.use(router);
app.use(BootstrapVue3);
app.use(apolloProvider);
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAp6eXEr4uMjAVAcdZwSKP8nIzinrREMZ0'
  },
  installComponents: true
});

app.use(formKitPlugin, defaultConfig({
  plugins: [createMultiStepPlugin()]
}));

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(StoreReset);
app.use(pinia);
app.mount("#app");