import { defineStore } from "pinia";
import { ref } from 'vue';
import { useAuthStore } from "./authStore.js";
import { authRoot, deviceRoot } from "../vue-client.js";
import { usePreferenceStore } from "./preferenceStore.js";
import { getCompensatedDataset } from "../utilities/slotUtilities";
import { ExportUtility } from "../utilities/exportUtility";

export const useSlotStore = defineStore('slot', () => {
  const slots = ref(null); // Start with uninitialized
  const currentSlot = ref(null); // Slot object
  const deviceHistory = ref([]); // Slot history objects
  const deviceSnapshot = ref(null); // Slot snapshot object
  const deviceTimeZone = ref(null); // Time zone object
  const accountUsers = ref([]); // All users (if any)
  const timeSeriesHistory = ref(null); // Time series results
  const deviceWeatherLabel = ref('');

  // Cache slots internally
  async function fetchAccountSlots() {
    if (slots.value === null) {
      const authStore = useAuthStore();
      const authString = "Bearer " + authStore.authUser.authToken;
      const fetchString = `${deviceRoot}/slotSummaries/`;
      const response = await fetch(fetchString, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authString,
        },
      });

      const data = await response.json();
      slots.value = data;
    }
  }

  async function createSlot(payload) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchString = `${deviceRoot}/slots`;
    const response = await fetch(fetchString, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
      body: JSON.stringify(payload),
    });
  }

  async function fetchSlot(payload) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const slotId = payload.id;
    const fetchString = `${deviceRoot}/slots/${slotId}`;
    const response = await fetch(fetchString, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
    });

    const data = await response.json();
    currentSlot.value = data;
  }

  async function updateSlot(slotId, payload) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchString = `${deviceRoot}/slots/${slotId}`;
    const response = await fetch(fetchString, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    currentSlot.value = data;
    return data;
  }

  async function fetchDeviceHistory(payload) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchString = `${deviceRoot}/devices/${payload.slotId}/history/v2`;
    const response = await fetch(fetchString, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
      body: JSON.stringify(payload),
    });

    const hisReport = await response.json();
    hisReport.forEach((entry) => {
      entry.timestamp = new Date(entry.handshakeTime);
      entry.interpolated = false;
    });

    const sensorMap = ExportUtility.computeSensorValues(hisReport);
    const v0History = ExportUtility.getV0Histories(sensorMap, payload.slotId);
    (deviceHistory as any).value = v0History;
  }

  async function fetchTimeSeries(slotId, payload) {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchString = `${deviceRoot}/slots/${slotId}/timeseries`;
    const response = await fetch(fetchString, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    timeSeriesHistory.value = data;
  }

  // Return cloned version of device history
  function deepClone(obj) {
    if (Array.isArray(obj)) {
      return obj.map((item) => deepClone(item));
    } else if (typeof obj === 'object' && obj !== null) {
      const clone = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          clone[key] = deepClone(obj[key]);
        }
      }
      return clone;
    } else {
      return obj;
    }
  }

  function computeCompensatedHistory() {
    const slotModel = currentSlot.value.model;
    const compSensorName = 'waterTemp';
    const clonedResult = deepClone(deviceHistory.value);
    const preferenceStore = usePreferenceStore();
    const sensorsSelected = preferenceStore.getSensorSelected();
    if (clonedResult && clonedResult.sensors && clonedResult.sensors.length > 0 && clonedResult.sensors[0].history.length > 0) {
      clonedResult.sensors.forEach((sensor) => {
        const sensorName = sensor.sensorName;
        if (sensorsSelected.some((item) => item.sensor === sensorName && item.selected)) {
          const compDataset = getCompensatedDataset(sensorsSelected, clonedResult, sensorName, 'y_axis_1', compSensorName);
          const compDatasetHistory = compDataset.data.reverse();
          for (let index = 0; index < sensor.history.length; index++) {
            sensor.history[index].val = compDatasetHistory[index];
          }
        }
      })
    }

    return clonedResult;
  }

  function spikeRemovalImpl(points, spikeThreshold) {
    if (points.length < 3) {
      return points; // Need at least 3 points for comparison
    }

    for (let i = 1; i < points.length - 1; i++) {
      const currentValue = points[i];
      const prevValue = points[i - 1];
      const nextValue = points[i + 1];

      const diffWithPrev = Math.abs(currentValue - prevValue);
      const diffWithNext = Math.abs(currentValue - nextValue);

      if (diffWithPrev > spikeThreshold && diffWithNext > spikeThreshold) {
        // If a spike is detected, set the current value to the adjacent value with a smaller difference
        points[i] = diffWithPrev < diffWithNext ? prevValue : nextValue;
      }
    }

    return points;
  }

  function performSpikeRemoval(deviceHist) {
    const compensationEntries = [
      { sensor: "phycocyanin" },
      { sensor: "chlorA" },
      { sensor: "turbidity" }
    ];
    compensationEntries.forEach((compensationEntry) => {
      const sensorIndex = deviceHist.sensors.findIndex((x) => x.sensorName === compensationEntry.sensor);
      const sensor = deviceHist.sensors[sensorIndex];
      const sensorValues = sensor.history.map((item) => item.val);
      const threshold = 50;
      const sensorValuesWithoutSpike = spikeRemovalImpl(sensorValues, threshold);
      for (let index = 0; index < sensor.history.length; index++) {
        sensor.history[index].val = sensorValuesWithoutSpike[index];
      }
    });
  }

  async function fetchDeviceSnapshot() {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const slotId = currentSlot.value._id;
    const fetchString = `${deviceRoot}/slots/${slotId}/report`;
    const response = await fetch(fetchString, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
    });

    const data = await response.json();
    deviceSnapshot.value = data;
  }

  async function fetchTimeZone(payload) {
    const lat = payload.lat;
    const lng = payload.lng;
    const timeNow = Math.round(Date.now() / 1000);
    const geocodeUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timeNow}&key=AIzaSyAp6eXEr4uMjAVAcdZwSKP8nIzinrREMZ0`;
    const response = await fetch(geocodeUrl);
    const data = await response.json();
    deviceTimeZone.value = data;
  }

  async function fetchWeatherLabel(payload) {
    const lat = payload.lat;
    const lng = payload.lng;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAp6eXEr4uMjAVAcdZwSKP8nIzinrREMZ0`;
    const response = await fetch(geocodeUrl);
    const data = await response.json();
    deviceWeatherLabel.value = data;
  }

  async function fetchAccountUsers() {
    const authStore = useAuthStore();
    const authString = "Bearer " + authStore.authUser.authToken;
    const fetchString = `${authRoot}/users/`;

    const response = await fetch(fetchString, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authString,
      },
    });
    const data = await response.json();
    accountUsers.value = data;
  }

  return {
    slots,
    currentSlot,
    deviceHistory,
    timeSeriesHistory,
    deviceSnapshot,
    deviceTimeZone,
    accountUsers,
    deviceWeatherLabel,
    createSlot,
    fetchSlot,
    fetchAccountSlots,
    updateSlot,
    fetchDeviceHistory,
    fetchDeviceSnapshot,
    fetchTimeZone,
    fetchWeatherLabel,
    fetchAccountUsers,
    computeCompensatedHistory,
    fetchTimeSeries,
    performSpikeRemoval
  };
});