<template>
  <Suspense>
    <router-view />
    <template #fallback>
      Loading...
    </template>
  </Suspense>

</template>

<style lang="scss">
$primary: #00407F;
$secondary: #005FBE;
$textHeaders: #d7d7d8;
@import 'bootstrap/dist/css/bootstrap.css';
@import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
@import 'vue3-toastify/dist/index.css';
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import url("https://fonts.googleapis.com/css?family=Jura");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

body {
  background-color: rgb(40, 45, 56);
}

#app {
  font-family: "Jura", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.formkit-tab-label, .formkit-label {
  color: white;
}
</style>
