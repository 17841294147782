import { defineStore, getActivePinia } from "pinia";
import {getUserLogoOrganizationId} from "../utilities/userUtilities";
import { apolloClient, onLogout } from "../vue-client";

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index);
    }
}

export const useAuthStore = defineStore('auth', 
{
    state: () => {
        return {
            authUser: null
        }
    },
    getters: {
        isArt(state) {
            if (state.authUser) {
                return state.authUser.email.endsWith('aquarealtime.com');
            }
            else {
                return false;
            }
        }
    },
    actions: {
        signin(user) {
            this.authUser = user;
        },
        async logout() {
            const logoOrganizationId = getUserLogoOrganizationId();
            this.authUser  = null;
            const storeMap = getActivePinia()._s;
            const stores = [...storeMap];
            await asyncForEach(stores, async (storeTuple)=> {
                const store = storeTuple[1];
                await store.$reset();
            })

            onLogout(apolloClient);
            return logoOrganizationId;
        } 
    },
    persist: true
});