<template>
    <div v-if="!weatherError" class="Weather">
        <b-container style="margin-bottom: 5px">
            <!-- current weather -->
            <b-row style="weatherRow">
                <!-- location -->
                <p class="weatherLabel">{{ currentWeather.weatherLocation }}</p>
            </b-row>
            <b-row style="weatherRow" v-if= "weatherResponse.weatherFetched">
                <b-col class="weatherCol">
                    <!-- img and Wind -->
                    <b-row class="displayBlock">
                        <!-- -->
                        <b-col cols="4" class="weatherCol displayBlock">
                            <!-- style="display:flex; align-items:center;"  -->
                            <img class="weatherNowImg" :src="`/assets/weather/${currentWeather.weatherNow.imgSrc}`" />
                        </b-col>
                        <b-col cols="8" class="weatherCol">
                            <b-row class="displayBlock">
                                <!-- wind -->
                                <p v-if="metric" class="weatherLabel weatherDetail">
                                    wind: {{ Math.round(Math.max(milesPerHrToKilometerPerHour(currentWeather.weatherNow.windGust), milesPerHrToKilometerPerHour(currentWeather.weatherNow.windSpeed))) }} kph
                                </p>
                                <p v-else class="weatherLabel weatherDetail">
                                    wind: {{ Math.max(currentWeather.weatherNow.windGust, currentWeather.weatherNow.windSpeed) }} mph
                                 </p>
                            </b-row>
                            <b-row class="displayBlock">
                                <b-col cols="6" class="weatherCol">
                                    <img src="/assets/icons/arrowUp.png"
                                        style="width:15px; margin-right:2%; float:right"
                                        :style="`transform:rotate(${currentWeather.weatherNow.windDirection}deg);`" />
                                </b-col>
                                <b-col cols="6" class="weatherCol">
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; "
                                        v-if="currentWeather.weatherNow.windDirection > 315">
                                        NW
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; "
                                        v-else-if="currentWeather.weatherNow.windDirection > 270">
                                        W
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; "
                                        v-else-if="currentWeather.weatherNow.windDirection > 225">
                                        SW
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px;"
                                        v-else-if="currentWeather.weatherNow.windDirection > 180">
                                        S
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; "
                                        v-else-if="currentWeather.weatherNow.windDirection > 135">
                                        SE
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; "
                                        v-else-if="currentWeather.weatherNow.windDirection > 90">
                                        E
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; "
                                        v-else-if="currentWeather.weatherNow.windDirection > 45">
                                        NE
                                    </p>
                                    <p class="weatherLabel weatherDetail" style="margin-left:2px; " v-else>N</p>
                                </b-col>
                            </b-row>
                            <b-row class="displayBlock">
                                <!-- humidty -->
                                <p class="weatherLabel weatherDetail">humidity: {{ currentWeather.weatherNow.humidity }}%</p>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="weatherCol">
                    <!-- Current Temp -->
                    <b-row class="weatherRow">
                        <h2 v-if="metric" class="currentTemp" style="margin-bottom: 2px">
                            {{ Math.round(currentWeather.weatherNow.temp) }} &deg;{{ "C" }}
                        </h2>
                        <h2 v-else class="currentTemp" style="margin-bottom: 2px">
                            {{ Math.round(ctoF(currentWeather.weatherNow.temp)) }} &deg;{{ "F" }}
                        </h2>
                    </b-row>
                    <b-row>
                        <b-col class="weatherCol">
                            <p v-if="metric" class="weatherLabel weatherDetail" style="float:right; margin-right: 5px;">
                                high: {{ Math.round(currentWeather.weatherNow.high) }} &deg;{{ "C" }}
                            </p>
                            <p v-else class="weatherLabel weatherDetail" style="float:right; margin-right: 5px;">
                                high: {{ Math.round(ctoF(currentWeather.weatherNow.high)) }} &deg;{{ "F" }}
                            </p>
                        </b-col>
                        <b-col class="weatherCol">
                            <p v-if="metric" class="weatherLabel weatherDetail" style="float:left; margin-left: 5px;">
                                low: {{ Math.round(currentWeather.weatherNow.low) }} &deg;{{ "C" }}
                            </p>
                            <p v-else class="weatherLabel weatherDetail" style="float:left; margin-left: 5px;">
                                low: {{ Math.round(ctoF(currentWeather.weatherNow.low)) }} &deg;{{ "F" }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
        <b-container>
            <b-row align-v="center" class="no-gutters" style="justify-content: space-between;"
                v-for="row in currentWeather.dailyWeather" v-bind:key="row.date">
                <!-- forecast -->
                <b-col cols="3">
                    <p class="weatherLabel">{{ row.dayOfWeek }}</p>
                </b-col>
                <b-col cols="2" class="weatherRow">
                    <img class="weatherImg" :src="`/assets/weather/${row.imgSrc}`" />
                </b-col>
                <b-col cols="1">
                    <p v-if="metric" class="weatherLabel" style="float:right; margin-right: 3px">
                        {{ row.temp.max }}
                    </p>
                    <p v-else class="weatherLabel" style="float:right; margin-right: 3px">
                        {{ Math.round(ctoF(row.temp.max)) }}&deg;
                    </p>
                </b-col>
                <b-col cols="1">
                    <p v-if="metric" class="weatherLabel" style="margin-left: 3px; color:#9facc2">
                        {{ row.temp.min }}
                    </p>
                    <p v-else class="weatherLabel" style="margin-left: 3px; color:#9facc2">
                        {{ Math.round(ctoF(row.temp.min)) }}&deg;
                    </p>
                </b-col>
                <b-col cols="4" style="display: inline-flex; ">
                    <img style="height:20px; vertical-align:middle; " src="/assets/weather/umbrella.png" />
                    <p class="weatherLabel" style="margin: auto 3px auto 3px">{{ row.precipProb.max }}%</p> 
                    <p class="weatherLabel" style="margin-left: 8px">
                        <div v-if="metric">
                            {{ Math.round(inchToMm(row.precipTotal.total)) }} mm    
                        </div>
                        <div v-else>
                            {{ row.precipTotal.total }} in
                        </div>
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <div v-else class="weatherRow">
        <p class="weatherLabel">No Weather Data Available</p>
    </div>
</template>

<script setup lang="ts">
import { useSlotStore } from "../stores/slotStore";
import { computed, reactive } from "vue";

const tS = "BbGqKNZdJwR2N7BYdTD1FXs3mHuSIFiF";

// https://www.reddit.com/r/vuejs/comments/kkac96/async_computed_property_for_vue_3/
const weatherResponse = reactive({
    weatherNow: {
        temp: 0,
        precipProb: 0,
        precipType: 0,
        cover: 0,
        windDirection: 0,
        windGust: 0,
        windSpeed: 45,
        humidity: 0,
        weatherCode: 0,
        imgSrc: "sun.png",
        high: 0,
        low: 0,
    },
    dailyWeather: [],
    weatherError: false,
    weatherLocation: '',
    weatherFetched: false
});

// The heart and soul of weather display. By using computed, we will trigger recomputation whether properties (e.g. lat/long)
// change. By returning a reactive property, we support async fetching.
const currentWeather = computed(() => {
    fetchWeather();
    return weatherResponse;
})

const props = defineProps({
    lat: String,
    long: String,
    metric: String
});

async function getForecast(inLat, inLong, timeZone) {
    const fields =
        "temperature,precipitationProbability,precipitationIntensity,precipitationType,cloudCover,windDirection,windGust,windSpeed,humidity,weatherCode";
    const timesteps = "1h";
    const param = `https://api.tomorrow.io/v4/timelines?location=${inLat},${inLong}&fields=${fields}&timesteps=${timesteps}&timezone=${timeZone}&units=metric&apikey=${tS}`;
    const response = await fetch(param);
    return await response.json();
}

async function createWeather(forecast) {
    //
    const isNight = new Date().getHours() > 19 && new Date().getHours() < 6;
    // current weather displayed first
    const current = {
        temp: forecast[0].values.temperature,
        precipProb: forecast[0].values.precipitationProbability,
        precipType: forecast[0].values.precipitationType,
        cover: forecast[0].values.cloudCover,
        windDirection: Math.round(forecast[0].values.windDirection),
        windGust: Math.round(forecast[0].values.windGust * 2.237),
        windSpeed: Math.round(forecast[0].values.windSpeed * 2.237),
        humidity: forecast[0].values.humidity,
        weatherCode: forecast[0].values.weatherCode,
        imgSrc: getWeatherImg([forecast[0].values.weatherCode], isNight),
        high: 0,
        low: 0,
    };

    weatherResponse.weatherNow = current;
    // weather is displayed for 5 days
    const days = [...new Set(forecast.map((x) => x.startTime.split("T")).map((x) => x[0]))];
    // each day will have max / min temp + percip + vis
    var dailyWeather = [];
    const dayOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];
    for (let i = 0; i < days.length; i++) {
        var day = forecast.filter((x) => x.startTime.includes(days[i]));
        if (i == 0) {
            current.high = Math.round(
                Math.max.apply(
                    Math,
                    day.map((x) => x.values.temperature)
                )
            );
            current.low = Math.round(
                Math.min.apply(
                    Math,
                    day.map((x) => x.values.temperature)
                )
            );
        } else {
            if (days[i]) {
                const currentDate = new Date(days[i]);
                const dateValue = currentDate.getDay();
                dailyWeather.push({
                    date: days[i].split("-")[1] + "/" + days[i].split("-")[2],
                    dayOfWeek: dayOfWeek[dateValue],
                    temp: {
                        max: Math.round(
                            Math.max.apply(
                                Math,
                                day.map((x) => x.values.temperature)
                            )
                        ),
                        min: Math.round(
                            Math.min.apply(
                                Math,
                                day.map((x) => x.values.temperature)
                            )
                        ),
                    },
                    precipProb: {
                        max: Math.max.apply(
                            Math,
                            day.map((x) => x.values.precipitationProbability)
                        ),
                        min: Math.min.apply(
                            Math,
                            day.map((x) => x.values.precipitationProbability)
                        ),
                    },
                    precipType: {
                        type: [...new Set(day.map((x) => x.values.precipitationType))],
                    },
                    precipTotal: {
                        total:
                            Math.round(
                                (day.reduce((a, b) => a + (b.values.precipitationIntensity || 0), 0) / 25.4) * 100
                            ) / 100, //mm to in
                    },
                    cover: {
                        max: Math.max.apply(
                            Math,
                            day.map((x) => x.values.cloudCover)
                        ),
                        min: Math.min.apply(
                            Math,
                            day.map((x) => x.values.cloudCover)
                        ),
                    },
                    weatherCode: {
                        code: [...new Set(day.map((x) => x.values.weatherCode))],
                    },
                    imgSrc: "",
                });
            }

            dailyWeather[i - 1].imgSrc = getWeatherImg(dailyWeather[i - 1].weatherCode.code, 0);
        }
    }
    weatherResponse.dailyWeather = dailyWeather;
    // output as array of objects to view
}

function getWeatherImg(weatherCodes, isNight) {
    var img;
    if (
        weatherCodes.includes(8000, 4201) ||
        weatherCodes.includes(8000, 4200) ||
        weatherCodes.includes(8000, 4100)
    ) {
        img = "lightningRain";
        if (isNight) {
            img += "Night";
        }
    } else if (
        weatherCodes.includes(7102) ||
        weatherCodes.includes(7101) ||
        weatherCodes.includes(7000)
    ) {
        img = "hail";
        if (isNight) {
            img += "Night";
        }
    } else if (
        weatherCodes.includes(6201) ||
        weatherCodes.includes(6200) ||
        weatherCodes.includes(6001) ||
        weatherCodes.includes(6000)
    ) {
        img = "sleet";
        if (isNight) {
            img += "Night";
        }
    } else if (
        weatherCodes.includes(5000, 3002) ||
        weatherCodes.includes(5000, 3001) ||
        weatherCodes.includes(5000, 3000) ||
        weatherCodes.includes(5001)
    ) {
        img = "breezySnow";
    } else if (
        weatherCodes.includes(5000) ||
        weatherCodes.includes(5101) ||
        weatherCodes.includes(5100)
    ) {
        img = "snow";
        if (isNight) {
            img += "Night";
        }
    } else if (weatherCodes.includes(4201) || weatherCodes.includes(4001)) {
        img = "rain";
        if (isNight) {
            img += "Night";
        }
    } else if (weatherCodes.includes(4200) || weatherCodes.includes(4000)) {
        img = "scatteredShowers";
        if (isNight) {
            img += "Night";
        }
    } else if (
        weatherCodes.includes(3000) ||
        weatherCodes.includes(3001) ||
        weatherCodes.includes(3002)
    ) {
        img = "clearWind";
        if (isNight) {
            img = "wind";
        }
    } else if (weatherCodes.includes(2000) || weatherCodes.includes(2100)) {
        img = "fog";
        if (isNight) {
            img += "Night";
        }
    } else if (weatherCodes.includes(1102)) {
        img = "mostlyCloudy";
        if (isNight) {
            img += "Night";
        }
    } else if (
        weatherCodes.includes(1101) ||
        weatherCodes.includes(1100) ||
        weatherCodes.includes(1001)
    ) {
        img = "partlyCloudy";
        if (isNight) {
            img += "Night";
        }
    } else {
        img = "sun";
        if (isNight) {
            img = "clearNight";
        }
    }
    return img + ".png";
}

const ctoF = (val) => {
    return val * (9 / 5) + 32;
};

const milesPerHrToMeterPerSec = (val) => {
    return val * 0.44704;
}

const milesPerHrToKilometerPerHour = (val) => {
    return val * 1.60934;
}

const inchToMm = (val) => {
    return val * 25.4;
}
function isValidGPS(latitude, longitude) {
    return latitude <= 90 && longitude <= 180;
}
async function fetchWeather() {
    // Compute weatherResponse
    let timeZoneString = "America/Denver";
    let weatherLabel = "";
    let forecastData = [];
    const slotStore = useSlotStore();
    const deviceTimeZone = slotStore.deviceTimeZone;
    if ((deviceTimeZone !== null) && isValidGPS(props.lat, props.long)){
        const deviceWeatherLabel = slotStore.deviceWeatherLabel;
        if (deviceTimeZone.status === "OK") {
            timeZoneString = deviceTimeZone.timeZoneId;
        }

        const forecast = await getForecast(props.lat, props.long, timeZoneString);
        if (deviceWeatherLabel.results) {
            weatherLabel = deviceWeatherLabel.results[Math.floor(deviceWeatherLabel.results.length / 2)].formatted_address;
        }
   
        forecastData = forecast.data.timelines[0].intervals;
        if (forecastData == 0) {
            // no weather returned
            weatherResponse.weatherError = true;
        } else {
            await createWeather(forecastData);
            weatherResponse.weatherLocation = weatherLabel;
        }
        weatherResponse.weatherFetched = true;
    }
};

</script>


<style scoped lang="scss">
// Extra small screen / phone
$screen-xs: 425px !default;
// Small screen / tablet
$screen-sm: 768px !default;
// Medium screen / desktop
$screen-md: 1023px !default;
// Large screen / wide desktop
$screen-lg: 1560px !default;

.container {
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
}

.displayBlock {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.weatherLabel {
    color: #f8f9fa;
    // text-align: center;
    margin: auto;
    vertical-align: middle;
    // font-size: 0.8rem;
}

.currentTemp {
    color: #f8f9fa;
    margin: auto;
    vertical-align: middle;
}

.weatherDetail {
    font-size: small;
}

.Weather {
    background-color: rgb(50, 59, 77) !important;
}

.weatherRow {
    justify-content: center;
    text-align: center;
}

.weatherCol {
    padding: 0;
}

.weatherImg {
    height: 30px;
}

.weatherNowImg {
    max-width: 60px;
    max-height: 60px;
    padding: 0;
    justify-content: flex-end;
    margin-right: 3px;
}

.weatherError {
    background-image: url("/assets/weather/weatherError.png");
    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: ($screen-sm)) {
    .container {
        padding-left: 1px;
        padding-right: 1px;
    }

    .weatherLabel {
        font-size: 0.8rem;
    }
}

@media (min-width: ($screen-md)) {
    .container {
        max-width: 800px;
        padding-left: 4%;
        padding-right: 4%;
    }
}
</style>