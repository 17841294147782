<template>
  <Doughnut 
    v-if="chartdata"
    :key="key"
    :chart-data="chartdata"
    :chart-options="options"
    :chart-id="chartId"
    :width="width"
    :height="height"
    :css-classes="cssClasses"
    :styles="styles"
  >
  </Doughnut>
</template>

<script setup lang="ts">
import { Doughnut } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

defineProps({
    key: {
      type: String,
      default: ''
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    chartdata: {
      type: Object,
      default:  null
    },
    options: {
      type: Object,
      default: null
    },
  });
</script>