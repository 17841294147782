<template>
  <div style="display: flex;flex-direction: row;width: 100%">
    <div v-if="zoomEnable" style="height: 100%;font-size30px;margin-left: 10px;width: 40px;display: flex;flex-direction: column;justify-content: flex-end;padding-bottom: 80px">
      <button @click="zoomIn" style="width: 30px;height: 30px;background-color:transparent;color: white;border: none;margin-bottom: 40px">
        <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="648"><path d="M484.693333 170.666667h59.989334c5.333333 0 7.978667 2.688 7.978666 8.021333v704c0 5.333333-2.645333 7.978667-7.978666 7.978667h-60.032c-5.333333 0-7.978667-2.688-7.978667-8.021334v-704c0-5.333333 2.645333-7.978667 7.978667-7.978666z" fill="#fffff" p-id="649"></path><path d="M178.688 492.672h672c5.333333 0 7.978667 2.645333 7.978667 7.978667v60.032c0 5.333333-2.688 7.978667-8.021334 7.978666H178.688c-5.333333 0-7.978667-2.645333-7.978667-7.978666v-60.032c0-5.333333 2.688-7.978667 8.021334-7.978667z" fill="#ffffff" p-id="650"></path></svg>
      </button>
      <button @click="reset" style="width: 30px;height: 30px;background-color: transparent;color: white;border: none;;margin-bottom: 40px">
        <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2332"><path d="M286.77 350a318.88 318.88 0 0 1 451 0q4.5 4.5 8.8 9.16a31.94 31.94 0 0 0 42.21 4.08 32 32 0 0 0 4.57-47.74q-5.05-5.46-10.34-10.74a382.57 382.57 0 0 0-541.46 0q-4.94 4.94-9.67 10a32 32 0 0 0 4.56 47.76 32 32 0 0 0 42.19-4.07q3.96-4.24 8.14-8.45zM802.47 644.58a32 32 0 0 0-43.62 5.71q-9.93 12.08-21.13 23.29a318.86 318.86 0 0 1-450.95 0q-10.57-10.58-20-21.93a31.89 31.89 0 0 0-43.24-5.25 32 32 0 0 0-5.91 46.22q11.29 13.59 23.91 26.21a382.57 382.57 0 0 0 541.46 0q13.5-13.49 25.48-28.1a32 32 0 0 0-6-46.15z" fill="#ffffff" p-id="2333"></path><path d="M743.27 250.23m32 0l0 0q32 0 32 32l0 60.33q0 32-32 32l0 0q-32 0-32-32l0-60.33q0-32 32-32Z" fill="#ffffff" p-id="2334"></path><path d="M206.79 637.69m32 0l0 0q32 0 32 32l0 60.33q0 32-32 32l0 0q-32 0-32-32l0-60.33q0-32 32-32Z" fill="#ffffff" p-id="2335"></path></svg>      </button>
      <button @click="zoomOut" style="width: 30px;height: 30px;background-color: transparent;color: white;border: none;">
        <svg class="icon" style="width: 1em;height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1393"><path d="M170.666667 469.333333h682.666666v85.333334H170.666667z" fill="#ffffff" p-id="1394"></path></svg>
      </button>
    </div>
    <Line
        v-if="chartdata"
        :chartData="chartdata"
        :chartOptions="options"
        :chartId="chartId"
        :width="width"
        :height="height"
        :cssClasses="cssClasses"
        :styles="styles"
        :plugins="plugins"
        ref="lineChart"
        style="flex-grow: 1;touch-action: none;user-select:none;"
    >
    </Line>
  </div>
</template>

<script setup lang="ts">
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import {onMounted, ref,useTemplateRef} from "vue";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
    annotationPlugin,
    zoomPlugin
)

const props = defineProps({
  chartId: {
    type: String,
    default: 'line-chart'
  },
  width: {
    type: Number,
    default: 400
  },
  height: {
    type: Number,
    default: 400
  },
  cssClasses: {
    default: '',
    type: String
  },
  styles: {
    type: Object,
    default: () => {
    }
  },
  plugins: {
    type: Array,
    default: () => []
  },
  chartdata: {
    type: Object,
    default: null
  },
  options: {
    type: Object,
    default: null
  },
  zoomEnable: {
    default: false,
    type: Boolean
  }
});

const lineChart = useTemplateRef('lineChart')
const maxValue = {
  y_axis_1_max:0,
  y_axis_2_max:0,
  init:false
}
onMounted(() => {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault(); // 禁止页面滑动，允许图表缩放
    }
  }, { passive: false });
  if (props.zoomEnable) {
    if (lineChart.value && lineChart.value.chart) {
      console.log('init line zoom')
      const chartInstance = lineChart.value.chart;
      chartInstance.options.plugins.zoom = {
        pan:{
          enable:true,
          mode:'y'
        },
        zoom:{
          wheel:{
            enable:true,
          },
          pinch:{
            enable: true
          },
          mode:'y',
          onZoom:({chart}) => {
            chart.options.scales.y_axis_1.min = 0
            chart.options.scales.y_axis_2.min = 0
            chart.update()
          }
        },
      }
    }
  }
})
function zoomIn() {
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      if (!maxValue.init) {
        maxValue.init = true
        maxValue.y_axis_1_max = chartInstance.options.scales.y_axis_1.max
        maxValue.y_axis_2_max = chartInstance.options.scales.y_axis_2.max
      }
      chartInstance.zoom(1.2)
    }
  }
}
function zoomOut(){
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      if (!maxValue.init) {
        maxValue.init = true
        maxValue.y_axis_1_max = chartInstance.options.scales.y_axis_1.max
        maxValue.y_axis_2_max = chartInstance.options.scales.y_axis_2.max
      }
      chartInstance.zoom(0.8)
    }
  }
}
function reset() {
  if (!maxValue.init) {
    return
  }
  if (lineChart.value && lineChart.value.chart) {
    const chartInstance = lineChart.value.chart;
    if (chartInstance) {
      chartInstance.options.scales.y_axis_1.max = maxValue.y_axis_1_max
      chartInstance.options.scales.y_axis_2.max = maxValue.y_axis_2_max
      chartInstance.update()
    }
  }
}
</script>
